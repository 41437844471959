<template>
  <v-app>
    <!-- CONTENT VIEWS -->
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({}),
}
</script>