import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import sass from './sass/main.scss'
// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// library icons
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import VuetifyLib from 'vuetify/lib'
Vue.use(VuetifyLib)
export default new VuetifyLib({
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  sass,
  render: h => h(App)
}).$mount('#app')
