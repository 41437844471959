<template>
  <div class="bg-main url-form-view">
    <v-row class="my-auto">
      <v-col cols="2" class="mx-2">
        <v-btn class="btn-normal" @click="goBack"><v-icon right dark>mdi-chevron-left</v-icon><span class="pl-2">VOLVER</span></v-btn>
      </v-col>
      <v-col class="d-flex align-center">
        <span class="text-base-bold color-green font-size-16">Nombre: </span>
        <span class="pl-2 text-base-bold color-black font-size-16">({{field}}) - {{title}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <iframe id="frameGisMap"
          title="Transaction"
          width="100%"
          height="840px"
          :src="iframeUrl">
        </iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from '../config.json';
export default {
  data: () => ({
    field: "?",
    title: "?",
    iframeUrl: config.hostGis
  }),
  mounted(){
    //console.log(">>>> data: ", this.$route.params.data);
    //console.log(">>>> table: ", this.$route.params.table);
    //console.log(">>>> variable: ", this.$route.params.variable);
    this.field = this.$route.params.table.name;
    this.title = this.$route.params.table.title;
    this.iframeUrl += this.field
  },
  methods: {
    goBack(){
      this.$router.push({
        path: "/home",
      });
    }
  }
}
</script>