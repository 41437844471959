import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import FormView from '../views/FormView.vue'
import GisMap from '../views/GisMap.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login', 
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/home',
    component: Home,
    name: 'home',
    props: true,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        component: FormView,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "gismap",
        path: "gismap",
        component: GisMap,
        meta: {
          requiresAuth: true,
        },
      },
    ]
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  const currentUser = window.localStorage.infoUser ? JSON.parse(window.localStorage.infoUser).token : undefined;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if(requiresAuth && !currentUser){
    next("/");
  }
  else if(!requiresAuth && currentUser){
    let path = to.path === from.path ? "/home" : from.path
    next(path)
  }
  else next();

});

export default router
