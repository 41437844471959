<template>
  <div class="login">
    <v-app>
			<v-main class="bg-login">
				<v-row class="position-login">
					<v-col cols="12" sm="12" md="12" lg="4" xl="4" class="m-0 p-0">
						<v-img class="d-none d-lg-flex d-xl-flex" src="../assets/img/bg-white-login.svg" width="100%">
							<v-img class="position-logo-login" src="../assets/logos/logo_via_plata.svg" width="30%"></v-img>
						</v-img> 
					</v-col>
					<v-col cols="11" sm="8" md="6" lg="4" xl="4" class="style-panel-login">
						<div class="panel-logo-responsive d-block d-sm-block d-md-block d-lg-none d-xl-none"><v-img class="mx-auto" src="../assets/logos/logo_via_plata.svg" width="20%" height="20%"></v-img></div>
						<div class="form-login">
							<v-form id="idFormLogin" class="formLogin" ref="formLogin" v-model="valid" lazy-validation>
                <v-row>
                  <v-col class="d-flex justify-center mb-4">
                    <div style="width: 120px">
                      <v-img src="../assets/logos/logo_GRID_color.svg"></v-img>
                    </div>
                  </v-col>
                </v-row>

								<div class="login-text-title">Introducir datos para acceder a GRID</div>
								<v-text-field 
									id="idName" 
									class="textFieldStyle mt-5" 
									label="Nombre de usuario" 
									placeholder="Nombre de usuario"
									v-model="valueNameUser"
									@keyup.enter="loadingLogin"
									:rules="[rules.required]"
									background-color="#e0e9e9c0"
									color="#385031"
									filled 
									required
								></v-text-field>
								<v-text-field
									class="textFieldStyle input-group--focused"
									label="Contraseña"
									v-model="valuePasswUser"
									@keyup.enter="loadingLogin"
									:rules="[rules.required, rules.min]"
									:append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPassw ? 'text' : 'password'"
									name="passwordLogin"
									background-color="#e0e9e9c0"
									color="#385031"
									hint="Mínimo 8 caracteres"
									value=""
									@click:append="showPassw = !showPassw"
									filled
								></v-text-field>
								<div><a id="linkResetPassw" class="linkClass" href="#" @click="sendForgotPassw">¿Ha olvidado la contraseña?</a></div>
								<v-btn :disabled="!valid"  class="btn-normal mt-6" @click="loadingLogin">
									<span class="btn-text-white-bold">ACCEDER</span>
								</v-btn>
							</v-form>                        
						</div> 
					</v-col>
				</v-row>
			</v-main>
    </v-app>
    <!-- DIALOG -->
    <DialogError ref="dialogError" /> 
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#446d08">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#d7f7a7" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
import config from '../config.json';
import axios from "axios";
export default {
	data () {
		return {
      urlLogin: config.host + config.apiUrl + config.login,
      token: undefined,
      waitLoadScreen: false,
      textLoader: '',
      titleError: '',
      textError: '',
			valid: true,
			valueNameUser: '',
			valuePasswUser: '',
      authoritation: undefined,
			rules: {
				required: value => !!value || 'Campo obligatorio.',
				min: v => v.length >= 8 || 'Mínimo 8 caracteres',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			},
			showPassw: false,
		}
	},
  components: {
    DialogError: () => import("../components/dialogError"),
  },
	methods:{
		sendForgotPassw(){
			console.log("Solicitud para recuperar contraseña.");
		},
		async loadingLogin(){
			if(this.$refs.formLogin.validate()){
				this.waitLoadScreen = true;
				this.textLoader = "Cargando...";
				this.authoritation = await axios.post(this.urlLogin,
					{
						user: this.valueNameUser,
						password: this.valuePasswUser,
					},
					{
						headers:{ "Content-Type": "application/json" }
					})
					.then(authoritation => {
						this.token = authoritation.data.token;
						return authoritation;
					})
					.catch(function(error){
						console.error(error);
						return undefined;
					}); 
				if(this.authoritation){
					this.waitLoadScreen = false;
					window.localStorage.setItem("infoUser",JSON.stringify({ token: this.token, name: this.valueNameUser }));
					this.$router.push({path: '/home'});
				}
				else{
					this.waitLoadScreen = false;
					this.titleError = 'ACCESO DENEGADO';
					this.textError = 'El nombre de usuario o la contraseña son incorrectas';
					this.showDialogUserDenied();
				}
			}
		},
    // OPEN DIALOGS
    async showDialogUserDenied() {
      try {
        await this.$refs.dialogError.open(
          this.titleError,
          this.textError
        )
      } catch (error) {
        console.error(error);
      }
    },
	}
}
</script>